export const customerTestimonials = [
  {
    quote: 'Easy and convenient way to spread cheer, you get what you exactly want! As long as they give you enough!',
    stars: 5,
    customerType: 'Giftly Recipient',
    author: 'Bob M.',
    location: 'Pottstown, Pennsylvania',
  },
  {
    quote: 'Never heard of Giftly until my daughter sent me a birthday present. What a great idea, '
      + 'and so many options. Very good!',
    stars: 5,
    customerType: 'Giftly Recipient',
    author: 'Donna C.',
    location: 'Spokane, Washington',
  },
  {
    quote: 'It was a nice surprise and easily redeemed. Very flexible.',
    stars: 5,
    customerType: 'Giftly Recipient',
    author: 'Kelly B.',
    location: 'Bel Air, Maryland',
  },
  {
    quote: 'It\'s a nice and easy way to tell someone how much you appreciate them.',
    stars: 5,
    customerType: 'Giftly Recipient',
    author: 'Paula',
    location: 'Orlando, FL',
  },
  {
    quote: 'Great way to give a gift to someone who lives thousands of miles away. They receive the gift the day-of '
      + 'and you skip the hassle of mailing it out and hoping that it arrives on time.',
    stars: 4,
    customerType: 'Giftly Buyer',
    author: 'Alicia T.',
    location: 'Waynesville, North Carolina',
  },
  {
    quote: 'I like the idea of being able to receive the gift through a credit to my bank account instead of having '
      + 'to use it to buy online all the time.',
    stars: 5,
    customerType: 'Giftly Recipient',
    author: 'Bill',
    location: 'Syracuse, NY',
  },
  {
    quote: 'Fast, fun and easy to use, not just for me but for my friend who was the recipient of the gift.',
    stars: 5,
    customerType: 'Giftly Buyer',
    author: 'Jeanette S.',
    location: 'Trophy Club, Texas',
  },
  {
    quote: 'VERY great idea! I was a recipient and was very pleased with my gift!',
    stars: 5,
    customerType: 'Giftly Recipient',
    author: 'Megan',
    location: 'Krum, TX',
  },
  {
    quote: 'This was such an easy process. What a great idea!',
    stars: 5,
    customerType: 'Giftly Recipient',
    author: 'Sharon K.',
    location: 'Baltimore, Maryland',
  },
  {
    quote: 'Convenient and easy for gift giving from a family member far away.',
    stars: 5,
    customerType: 'Giftly Recipient',
    author: 'Sheila G.',
    location: 'Goshen, Indiana',
  },
  {
    quote: 'It\'s so easy and quick to use! It\'s also a fun way to send someone a gift and surprise them!',
    stars: 5,
    customerType: 'Giftly Recipient',
    author: 'Steph P.',
    location: 'Harrisburg, Pennsylvania',
  },
  {
    quote: 'Convenient when I knew I wanted to get a gift to thank someone but not sure how to get to the facility. '
      + 'It allows for you to buy anything anywhere for any amount and not have to drive.',
    stars: 5,
    customerType: 'Giftly Buyer',
    author: 'Lysa M.',
    location: 'Chester Springs, Pennsylvania',
  },
]
